html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: #4183c4;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
}

input:focus {
  outline: none;
  border: 1px solid #1890ff;
  box-shadow: 0 0 5px #719ece;
}

input:hover:enabled {
  border: 1px solid #1890ff;
}

input:disabled {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  opacity: 1;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.handwritten {
  font-family: 'Gochi Hand', cursive;
}

.ant-layout-content {
  max-width: 1800px;
  min-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 50px;
}

.ant-layout-header,
.ant-menu {
  background: #326295 !important;
  float: left;
}

.ant-menu-item,
.ant-menu-submenu-title {
  color: #fff !important;
}

.ant-input-search-button {
  background-color: #e57200;
  color: #fff;
  border-radius: 0;
  border-color: #e57200;
}

.ant-upload-drag-icon {
  margin-bottom: 0 !important;
}

.ant-upload.ant-upload-drag {
  padding: 0 !important;
}

.logo {
  cursor: pointer;
  float: left;
  width: 150px;
  margin-left: -50px;
  margin-top: -25px;
}

.ant-table-row.row-clickable {
  cursor: pointer;
}
